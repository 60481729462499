.react-select {
  &__control {
    border-color: #ced4da !important;

    &:hover {
      border-color: #ced4da !important;
    }

    &--is-focused {
      color           : #495057 !important;
      outline         : 0 !important;
      box-shadow      : 0 0 0 0.2rem rgba(33, 94, 111, 0.25) !important;
      border-color    : #47acc8 !important;
      background-color: #fff !important;
    }

    &--is-disabled {
      background-color: #e9ecef !important;
    }
  }

  &__placeholder {
    color    : #6c757d;
    font-size: 1rem;
  }
}
$danger : #EE3B46;
$primary: #215E6F;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import 'vars';
@import 'mixins';
@import '~bootstrap/scss/bootstrap';

h1 {
  margin-bottom: 2rem;
}

body {
  margin     : 0;
  padding    : 0;
  font-family: "Montserrat", sans-serif;
}

.touchable {
  &:hover {
    cursor : pointer;
    opacity: 0.75;
  }
}

.btn.btn-danger,
.btn.btn-outline-danger:hover,
.btn.btn-outline-danger:active {
  color: #fff;
}

.b-modal__footer {
  @extend .modal-footer;
  margin: 1rem -1rem -1rem -1rem;
}
$primary-color  : #EE3B46;
$secondary-color: #215E6F;

$text-primary  : #3A4046;
$text-secondary: #979797;

$star-color       : #FF9500;
$hover-color      : #F1F0F0;
$border-color     : #B6BAC2;
$background-color : #F3F1F1;
$error-background : rgba(238, 59, 70, 0.1);
$placeholder-color: #A2A8B0;

$border-radius : 0.25rem;

$screenSmMax: 599px;
$screenMdMin: 600px;
$screenMdMax: 1199px;
$screenLgMin: 1200px;

$text-primary-filter  : brightness(0) saturate(100%) invert(23%) sepia(7%) saturate(815%) hue-rotate(169deg) brightness(93%) contrast(89%);
$text-secondary-filter: brightness(0) saturate(100%) invert(63%) sepia(13%) saturate(0%) hue-rotate(183deg) brightness(91%) contrast(100%);
$white-color-filter   : brightness(0) saturate(100%) invert(95%) sepia(95%) saturate(0%) hue-rotate(19deg) brightness(106%) contrast(108%);
$primary-color-filter : brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(3151%) hue-rotate(335deg) brightness(95%) contrast(96%);
$secondary-color-filter: brightness(0) saturate(100%) invert(29%) sepia(58%) saturate(522%) hue-rotate(146deg) brightness(93%) contrast(89%);
@import '../../styles/vars';

.b-dropzone {
  div {
    outline: none !important;
  }

  &__inner {
    flex            : 1;
    color           : #848d9d;
    border          : 1px dashed #ced4da;
    padding         : 20px;
    display         : flex;
    transition      : border 0.24s ease-in-out;
    align-items     : center;
    border-radius   : $border-radius;
    flex-direction  : column;
    justify-content : center;
    background-color: #f5faff;

    &:hover {
      cursor: pointer;
    }

    &:active {
      opacity: 0.75;
    }

    img {
      width : 48px;
      filter: $secondary-color-filter;
    }
  }

  &__text {
    color     : $text-secondary;
    max-width : 350px;
    font-size : 16px;
    margin-top: 20px;
    text-align: center;
  }

  &--error {
    .b-dropzone__inner {
      background  : $error-background;
      border-color: $primary-color !important;
    }
  }
}

.b-preview {
  width    : 100%;
  height   : 100px;
  padding  : 0.2rem;
  display  : inline-flex;
  font-size: 12px;

  &__container {
    margin   : 0 -0.2rem;
    display  : flex;
    flex-wrap: wrap;
  }

  &__wrapper {
    width    : 33.3%;
    min-width: 110px;
  }

  &__inner {
    width        : 100%;
    border       : 1px solid #eaeaea;
    padding      : 0.2rem;
    display      : flex;
    border-radius: 2px;
  }

  &__item {
    width   : 100%;
    position: relative;

    img {
      width     : 100%;
      height    : 100%;
      display   : block;
      object-fit: cover;
    }
  }

  &__close {
    top             : 2px;
    right           : 2px;
    color           : $text-primary;
    width           : 16px;
    height          : 16px;
    cursor          : pointer;
    opacity         : 0.5;
    display         : inline-block;
    position        : absolute;
    font-size       : 16px;
    word-wrap       : break-word;
    text-align      : center;
    font-weight     : 100;
    line-height     : 1.1;
    border-radius   : 50%;
    background-color: white;

    &:hover {
      opacity: 1;
    }
  }
}
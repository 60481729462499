@use "sass:math";

@mixin font($size, $weight:false, $height:false) {
  $size-in-rem: math.div($size, 16px) * 1rem;

  font-size  : $size-in-rem;
  font-family: 'Montserrat', sans-serif;

  @if $height {
    line-height: ($height / $size) * $size-in-rem;
  }

  @if $weight {
    font-weight: $weight;
  }
}
@import "../../styles/vars";
@import "../../styles/mixins";

.b-drawer {
  &--open {
    .b-drawer__body {
      left: 0;
    }

    .b-drawer__toggle-icon {
      background: rgba(0, 0, 0, 0);

      &:after {
        transform: translateY(-5px) rotate(-45deg);
      }

      &:before {
        transform: translateY(5px) rotate(45deg);
      }
    }
  }

  &__body {
    top             : 0;
    left            : -400px;
    width           : 400px;
    height          : 100%;
    z-index         : 100;
    display         : flex;
    position        : fixed;
    min-width       : 400px;
    max-width       : 400px;
    transition      : 0.3s;
    box-sizing      : border-box;
    background-color: white;
  }

  &__overlay {
    top           : 0;
    left          : 0;
    width         : 100%;
    height        : 100%;
    z-index       : 3;
    position      : fixed;
    transition    : 0.5s;
    pointer-events: none;

    &--visible {
      opacity         : 1;
      pointer-events  : auto;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__content {
    flex    : 1;
    overflow: auto;

    .list-group-item {
      color          : $text-primary;
      border         : none;
      display        : flex;
      transition     : 0.4s;
      align-items    : center;
      justify-content: space-between;

      &.nested {
        padding-left: 30px;
      }

      &.opened img {
        transform: rotate(180deg);
      }

      &.active,
      &:active {
        color     : $primary-color;
        background: $hover-color;
      }

      &.active {
        font-weight: 500;
      }

      &:hover {
        color          : $primary-color;
        cursor         : pointer;
        text-decoration: none;

        img {
          filter: $primary-color-filter;
        }
      }

      span {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
      }
    }
  }

  &__container {
    width         : 100%;
    z-index       : 4;
    display       : flex;
    background    : #fff;
    flex-direction: column;
  }

  &__toggle {
    top            : 80px;
    right          : -40px;
    width          : 40px;
    height         : 40px;
    border         : none;
    position       : absolute;
    display        : flex;
    background     : white;
    box-shadow     : 2px 0 8px rgba(0, 0, 0, .15);
    align-items    : center;
    border-radius  : 0 4px 4px 0;
    justify-content: center;
  }

  &__toggle-icon {
    width     : 14px;
    position  : relative;
    background: #333;
    transition: background .3s cubic-bezier(.78, .14, .15, .86);

    &,
    &:after,
    &:before {
      height: 2px;
    }

    &:after,
    &:before {
      width     : 100%;
      content   : "";
      display   : block;
      position  : absolute;
      background: #333;
      transition: transform .3s cubic-bezier(.78, .14, .15, .86), -webkit-transform .3s cubic-bezier(.78, .14, .15, .86), -moz-transform .3s cubic-bezier(.78, .14, .15, .86);
    }

    &:after {
      top: 5px;
    }

    &:before {
      top: -5px;
    }
  }
}
@import '../../styles/vars';

.b-html-editor {

  &__main,
  &__toolbar {
    border       : 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
  }

  &__toolbar {
    margin-bottom             : 0 !important;
    border-bottom-left-radius : 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__main {
    padding                : 0 20px;
    border-top             : none !important;
    border-top-left-radius : 0 !important;
    border-top-right-radius: 0 !important;
  }

  &--error {
    .b-html-editor {

      &__main,
      &__toolbar {
        border-color: $primary-color !important;
      }
    }
  }
}
@import '../../styles/vars';

.b-crud {
  display: flex;

  img {
    height: 20px;

    &.filtred {
      filter: $text-primary-filter;
    }

    &:hover {
      cursor : pointer;
      opacity: 0.75;
    }
  }

  i {
    margin      : 0 10px;
    display     : block;
    border-right: 1px solid $border-color;
  }
}
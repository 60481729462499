@import "../../styles/mixins";
@import "../../styles/vars";

.b-tour-types {
  display        : flex;
  flex-direction : row;
  width          : 700px;
  justify-content: space-between;
  margin-bottom  : 20px;

  &__button {
    display        : flex;
    align-items    : center;
    justify-content: center;
    height         : 60px;
    width          : 200px;
    text-align     : center;
    border-radius  : 5px;
    border         : 1px solid #215E6F;
    transition     : 0.2s all;
    user-select    : none;

    &:hover {
      cursor    : pointer;
      box-shadow: 1px 3px 5px 0px $border-color;
      transition: 0.2s all;
    }

    &--disabled {
      &:hover {
        cursor    : default;
        box-shadow: none;
      }

      // border             : 1px solid $border-color;
      // color           : $border-color;
      background-color : $border-color
    }

    &--selected {
      background-color: #215E6F;
      color           : white;
    }
  }
}

.b-control-counter {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  width          : 500px;

  .price {
    font-weight : 600;
    margin-right: 10px;
    white-space : nowrap;
  }

  .counter-title {
    min-width: 160px;
  }

  padding-bottom: 10px;
  border-bottom : 1px solid $border-color;
  margin-bottom : 10px;

  .counter-buttons {

    display        : flex;
    align-items    : center;
    width          : 100px;
    justify-content: space-between;
    user-select    : none;
    margin-right   : 40px;

    &:hover {
      cursor: pointer;
    }

    &__minus {
      display        : flex;
      justify-content: center;
      align-items    : center;
      border-radius  : 50%;
      text-align     : center;
      width          : 30px;
      height         : 30px;
      margin-right   : 10px;
      border         : 1px solid $border-color;
      color          : $border-color;
    }

    &__plus {
      user-select    : none;
      text-align     : center;
      display        : flex;
      margin-left    : 10px;
      justify-content: center;
      align-items    : center;
      border-radius  : 50%;
      width          : 30px;
      height         : 30px;
      border         : 1px solid $border-color;
      color          : $border-color;
    }
  }
}

.b-people-range {
  display        : flex;
  width          : 400px;
  justify-content: space-between;
  align-items    : center;
  margin-bottom  : 20px;

  & div:first-child {
    margin-bottom: 0 !important;
  }

  .price {
    font-weight: 600;
  }
}

.b-total-price {
  display              : grid;
  grid-template-areas  : 'text price';
  grid-template-columns: 50px 150px;
  margin-bottom        : 30px;
  align-items          : center;
  gap                  : 5px 20px;


  span {
    font-weight : 600;
    justify-self: end;
  }
}

.url-link {
  user-select: none;

  &:hover {
    cursor: default;
  }
}


.b-free-control-counter {
  display              : grid;
  grid-template-areas  : 'title price counter input';
  grid-template-columns: 100px 150px 150px 270px;
  align-items          : center;
  border-bottom        : 1px solid $border-color;
  margin-bottom        : 5px;

  .price {
    font-weight : 600;
    white-space : nowrap;
    justify-self: end;
  }

  .counter-title {
    min-width: 160px;
  }


  .counter-buttons {

    display        : flex;
    align-items    : center;
    justify-self   : center;
    width          : 100px;
    justify-content: space-between;
    user-select    : none;

    &:hover {
      cursor: pointer;
    }

    &__button {
      display        : flex;
      justify-content: center;
      align-items    : center;
      border-radius  : 50%;
      text-align     : center;
      width          : 30px;
      height         : 30px;
      border         : 1px solid $border-color;
      color          : $border-color;
    }
  }
}
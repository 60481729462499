.b-header {
  width   : 100%;
  height  : 70px;
  z-index : 1;
  position: relative;

  &__logo img {
    width : 217px;
    height: 31px;
  }
}

.b-menu {
  width          : 100%;
  height         : 100%;
  padding        : 0 40px 0 60px;
  display        : flex;
  align-items    : center;
  align-items    : center;
  background     : #fff;
  box-shadow     : 0 0 6px #b6bac2;
  justify-content: space-between;

  ul {
    margin : 0;
    display: flex;
    padding: 0;
  }
}
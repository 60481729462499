@import '../../styles/vars';

.b-loading {
  &__icon {
    width  : 50px;
    height : 50px;
    display: inline-block;

    &:after {
      width        : 46px;
      height       : 46px;
      margin       : 1px;
      border       : 5px solid $primary-color;
      content      : ' ';
      display      : block;
      animation    : lds-dual-ring 1.2s linear infinite;
      border-color : $primary-color transparent $primary-color transparent;
      border-radius: 50%;
    }
  }

  &__inner:before {
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    z-index         : 98;
    content         : '';
    position        : absolute;
    animation       : Gradient 5s ease infinite;
    background      : linear-gradient(90deg, rgba(white, 0), rgba($primary-color, 0.4), rgba(white, 0));
    background-size : 400% 100%;
    background-color: rgba(white, 0.5);
  }

  &__spinner {
    top      : 50%;
    left     : 50%;
    z-index  : 99;
    position : absolute;
    transform: translate(-50%, -50%);
  }

  &__wrapper {
    overflow     : hidden;
    position     : relative;
    border-radius: 8px;
  }

  &__general {
    top            : 0;
    left           : 0;
    right          : 0;
    bottom         : 0;
    z-index        : 10;
    display        : flex;
    padding        : 20px;
    position       : fixed;
    background     : rgba(255, 255, 255, 0.5);
    text-align     : center;
    align-items    : center;
    justify-content: center;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
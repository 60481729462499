@import '../../styles/vars';

.b-rating {
  display    : flex;
  align-items: center;

  &__star {
    font-size  : 20px;
    line-height: 1;

    &:before {
      color  : $text-secondary;
      content: '\2605';
    }

    &--filled {
      &:before {
        color: $star-color;
      }
    }
  }

  &__value {
    margin-left: 8px;
  }
}
@import '../../styles/vars';

.react-datepicker {
  &__close-icon {
    padding: 0 8px;

    &:after {
      color     : $text-primary !important;
      width     : 20px !important;
      height    : 20px !important;
      display   : block !important;
      padding   : 0 !important;
      font-size : 22px !important;
      background: transparent !important;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}
@import "../../styles/mixins";

.b-login {
  height             : 100vh;
  overflow           : hidden;
  display            : flex;
  align-items        : center;
  justify-content    : center;
  background-size    : cover;
  background-image   : url('/images/signin-background.jpg');
  background-repeat  : no-repeat;
  background-position: center;

  h2 {
    text-align   : center;
    margin-bottom: 1rem;
    @include font(20px, 500);
  }

  &__container {
    width     : 500px;
    padding   : 50px;
    background: white;
  }
}
@import '../../styles/vars';

.b-chat {
  &__inner {
    max-width: 100%;
  }

  &__text {
    white-space: pre-wrap;
  }

  &__sender {
    width        : max-content;
    margin-left  : auto;
    margin-bottom: 1rem;

    div {
      text-align: end;
    }
  }
}

.b-photos {
  margin       : 0 -8px;
  touch-action : none;
  margin-bottom: 20px;

  &__image {
    width   : 100%;
    height  : 100%;
    position: relative;

    img {
      width         : 100%;
      height        : 100%;
      padding       : 8px;
      object-fit    : cover;
      user-select   : none;
      pointer-events: none;
    }
  }

  &__delete {
    top             : 14px;
    right           : 14px;
    color           : white;
    width           : 16px;
    height          : 16px;
    border          : none;
    padding         : 0;
    position        : absolute;
    font-size       : 20px;
    transition      : 0.4s;
    line-height     : 15px;
    border-radius   : 3px;
    background-color: $secondary-color;

    &:hover {
      background-color: #1c505f;
    }
  }

  &__select {
    top     : 14px;
    left    : 14px;
    position: absolute;

    input {
      margin: 0;
    }
  }
}